/* eslint-disable indent */
/* eslint-disable max-statements */
/* eslint-disable react/jsx-no-bind */
// modules
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFetchinitiativeListQuery } from 'features/adminApi/endpoints/initiative';
import DataTable from 'components/DataTable';
import Loading from 'components/Loading';
import { Icon, Tab, TabGroup } from '@nike/eds';
import { useHistory, useParams } from 'react-router-dom';
import Link from 'components/Link';
import styles from '../Edit/styles.module.scss';
import { InitiativeTypesEnum } from 'utils';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import { actions } from 'components/AdminNav/SubNavigationMenu/subnavUtils.js';
import { userInitiativePermissions } from 'components/RBAC';
import { ViewMode } from 'lib/enums';

const ExperienceListView = () => {
  const history = useHistory();
  const { initiativeType: selectedInitiativeType } = useParams();
  const [clickedCellHandle, setClickedCellHandle] = useState(null);

  const defaultInitiativeType = InitiativeTypesEnum.EXP;

  const initiativeType = [
    InitiativeTypesEnum.EXP,
    InitiativeTypesEnum.COR,
    InitiativeTypesEnum.QTX,
  ].includes(selectedInitiativeType)
    ? selectedInitiativeType
    : defaultInitiativeType;

  const filteredActions = actions(initiativeType);

  const handleChange = useCallback(
    (event) => {
      const type = event.target.id;
      history.push(`/initiative/${ type }`);
    },
    [initiativeType]
  );

  const userRowAction = (handle) => {
    const userInitPermissions = userInitiativePermissions(handle);

    return userInitPermissions.includes(ViewMode.EDIT.toUpperCase())
      ? 'edit'
      : userInitPermissions.includes(ViewMode.READ.toUpperCase())
      ? 'view'
      : null;
  };

  const handleCellClick = useCallback((action) => {
    const handle = action?.row?.handle;
    if (action.field !== 'actions') setClickedCellHandle(handle);
  }, []);

  const userInitPermissions = userRowAction(clickedCellHandle);

  useEffect(() => {
    if (clickedCellHandle && userInitPermissions) {
      history.push(
        `/initiative/${ initiativeType }/${ userInitPermissions }/${ clickedCellHandle }`
      );
    }
  }, [initiativeType, clickedCellHandle, userInitPermissions]);

  const {
    data: items,
    isFetchingIComplete,
    isFetching: isFetchingInProgress,
    isLoading,
  } = useFetchinitiativeListQuery(undefined, {
    // Filters out initiative based on their type
    // If initiativeType is undefined then passes them as it is
    selectFromResult: ({ data, error, isLoading }) => {
      return {
        data: initiativeType
          ? data?.filter(
              (item) =>
                item.initiativeType === initiativeType &&
                item.status !== 'deleted'
            )
          : data,
        error,
        isLoading,
      };
    },
  });

  const filteredRows = items
    ?.filter((initiative) => initiative.status !== 'deleted')
    .filter((initiative) => initiative.initiativeType === initiativeType);

  function getRowLink(params, action) {
    return `/initiative/${ initiativeType }/${ action }/${ params.row.handle }`;
  }

  const columns = useMemo(() => {
    const renderCell = (params, _) => {
      const userInitPermissions = userRowAction(params?.row?.handle);
      return (
        <Link
          to={ getRowLink(params, userInitPermissions) }
          className={ styles.linkWrapper }
        >
          <div className={ styles.icon }>
            { params.field === 'name' &&
              (userInitPermissions === 'edit' ? (
                <Icon name="Edit" size="s" />
              ) : userInitPermissions === 'view' ? (
                <Icon name="InfoCircle" size="s" />
              ) : (
                ''
              )) }
          </div>
          <div className={ styles.fieldName }>{ params.row[params.field] }</div>
        </Link>
      );
    };

    const columnsArray = [
      {
        field: 'name',
        headerName: 'Name',
        renderCell,
        minWidth: 250,
        flex: 1,
      },
      {
        field: 'handle',
        headerName: 'Handle',
        flex: 1,
        minWidth: 250,
        renderCell,
      },
      {
        field: 'shortHandle',
        headerName: 'Short Handle',
        minWidth: 250,
        flex: 1,
        renderCell,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 100,
        flex: 1,
        renderCell,
      },
      {
        field: 'platforms',
        headerName: 'Platforms',
        flex: 1,
      },
      {
        field: 'participantLimit',
        headerName: 'Participant Limit',
        flex: 1,
      },
      {
        field: 'requiredPpis',
        headerName: 'Required Profile Properties',
        flex: 1,
      },
      {
        field: 'participant count',
        headerName: 'Participant Count',
        valueGetter: (params) => {
          if (params && typeof params.getValue === 'function') {
            return params.getValue(params.id, 'participantCount') ?? '';
          }
          return '';
        },
        flex: 1,
      },
    ];

    if (selectedInitiativeType !== InitiativeTypesEnum.QTX) {
      columnsArray.splice(3, 0, {
        field: 'organization',
        headerName: 'Organization',
        minWidth: 100,
        flex: 1,
        renderCell,
      });
    }
    return columnsArray;
  }, []);

  const columnVisibilityModel = {
    platforms: false,
    participantLimit: false,
    requiredPpis: false,
    'participant count': false,
  };

  const getRowClassName = useCallback((params) => {
    return params.row.handle ? styles.clickableRow : '';
  }, []);

  const initialSortByName = [
    {
      field: 'name',
      sort: 'asc',
    },
  ];

  const initialFilter = useMemo(
    () => ({
      field: 'status',
      operator: 'equals',
      value: 'active',
    }),
    []
  );

  return (
    <>
      { isFetchingInProgress || isLoading ? (
        <Loading text="Fetching Experiences" />
      ) : (
        <>
          <TabGroup
            name="initiative-list-tabs"
            activeId={ initiativeType }
            onChange={ handleChange }
          >
            <Tab id={ InitiativeTypesEnum.EXP }>Digital Services</Tab>
            <Tab id={ InitiativeTypesEnum.COR }>Core Initiatives</Tab>
            <Tab id={ InitiativeTypesEnum.QTX }>Qualtrics Polls</Tab>
          </TabGroup>
          <DataTable
            loading={ isFetchingInProgress || isFetchingIComplete }
            heading={ `${ convertHandleToTitle(initiativeType) } Initiative List` }
            createHref={ `/initiative/${ initiativeType }/create` }
            createLabel="Create"
            columns={ columns }
            actions={ filteredActions }
            actionMenu={ true }
            getRowClassName={ getRowClassName }
            rows={ filteredRows ?? [] }
            sortModel={ initialSortByName }
            columnVisibilityModel={ columnVisibilityModel }
            handleCellClick={ handleCellClick }
            initialFilter={ initialFilter }
          />
        </>
      ) }
    </>
  );
};

export default ExperienceListView;
