import React from 'react';
import PropTypes from 'prop-types';
import {
  checkConditionalPosition,
  checkIfLastQuestion,
  getConditionalToggleValue,
  getHiddenSetFromStart,
  getPPIValue,
  isActivityGearModelQuestion,
  isFirstInConditionalSet,
} from '../../RepeatableFieldsetUtils';
import styles from './index.module.scss';
import { ActionButton } from './ActionButton';
import { fieldsTypes } from 'lib/propTypes';

export const FieldSetActions = ({
  fieldSet,
  index,
  repeatableFieldSet = [],
  handleMoveUpFieldSet,
  handleMoveDownFieldSet,
}) => {
  const fieldSetId = fieldSet.fieldSetId;
  const isUsedInConditional = fieldSet.isUsedInConditional;
  const isConditionalQuestion = getConditionalToggleValue(fieldSet);

  const ppiValue = getPPIValue(fieldSet);
  const currentHiddenSet = isActivityGearModelQuestion(ppiValue)
    ? getHiddenSetFromStart(index, repeatableFieldSet)
    : [];

  const conditionalQuestionPosition = isConditionalQuestion
    ? checkConditionalPosition(index, repeatableFieldSet)
    : null;

  const isLastQuestion = checkIfLastQuestion(
    index,
    conditionalQuestionPosition,
    repeatableFieldSet,
    isUsedInConditional,
    ppiValue,
    currentHiddenSet
  );

  const disableMoveUp =
    index === 0 || isFirstInConditionalSet(conditionalQuestionPosition);
  const disableMoveDown =
    index === repeatableFieldSet.length - 1 || isLastQuestion;

  return (
    <div className={ styles.arrowsContainer }>
      <ActionButton
        onClick={ handleMoveUpFieldSet(fieldSetId) }
        disabled={ disableMoveUp }
        icon="ArrowUp"
        label="Move Up"
      />
      <ActionButton
        onClick={ handleMoveDownFieldSet(fieldSetId) }
        disabled={ disableMoveDown }
        icon="ArrowDown"
        label="Move Down"
      />
    </div>
  );
};

FieldSetActions.propTypes = {
  fieldSet: PropTypes.shape({
    fieldSetId: PropTypes.number,
    isUsedInConditional: PropTypes.bool,
    hasConditionalToggled: PropTypes.bool,
    showReorderingMenu: PropTypes.bool,
    value: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  handleMoveDownFieldSet: PropTypes.func,
  handleMoveUpFieldSet: PropTypes.func,
  index: PropTypes.number,
  repeatableFieldSet: PropTypes.arrayOf(fieldsTypes.inquiryData),
};
