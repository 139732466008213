import { isArray } from 'lodash';
import { ACTIVITY_GEAR_PPIS } from './lib';
import { SURVEY_FORM } from 'lib/layoutUtils';

export const CategoryFields = {
  CATEGORY_NAME: 'categoryName',
  CATEGORY_TITLE: 'categoryTitle',
  CATEGORY_SUBTITLE: 'categorySubtitle',
  CATEGORY_TEXT: 'categoryText',
  BACKGROUND_IMAGE: 'backgroundImage',
  CATEGORY_CHOICE: 'categoryChoice',
};

export const QuestionSetTypes = {
  CONDITIONAL_TOGGLE: 'conditionalToggle',
  SELECTION_TYPE: 'selectionType',
  CONDITIONAL_OPTIONS: 'conditionalOptions',
  QUESTION_LABEL: 'questionLabel',
  OPTIONAL_QUESTION: 'optionalQuestion',
  QUESTION_CATEGORY: 'questionCategory',
  EXISTING_PPI: 'existingPPI',
  ANSWER_TYPE: 'answerType',
  ANSWER_OPTIONS: 'answerOptions',
  ...CategoryFields,
};

export const isCategoryField = (fieldName) => {
  const categoryFieldValues = Object.values(CategoryFields);
  return categoryFieldValues.includes(fieldName);
};

// Helper function to retrieve the last element of an array
export function getLastElement(arr) {
  if (isArray(arr)) {
    return arr.at ? arr.at(-1) : arr[arr.length - 1];
  }
  return undefined;
}

export const getFieldValueByFieldName = (questionSet, fieldName) => {
  if (isArray(questionSet)) {
    const field = questionSet?.find(
      ({ field = '' }) => typeof field === 'string' && field.includes(fieldName)
    );
    return field?.value;
  }
};

export const getFieldValuesByFieldNames = (questionSet, fieldNames) => {
  if (isArray(questionSet) && isArray(fieldNames)) {
    const matchingFields = questionSet.reduce((acc, item) => {
      const fieldNameMatches = fieldNames.find(
        (fieldName) =>
          typeof item.field === 'string' && item.field.includes(fieldName)
      );

      if (fieldNameMatches) {
        acc[fieldNameMatches] = item.value;
      }
      return acc;
    }, {});

    return matchingFields;
  }
  return {};
};

export const getFieldByFieldName = (questionSet, fieldName) => {
  if (isArray(questionSet)) {
    const field = questionSet?.find(
      ({ field = '' }) => typeof field === 'string' && field.includes(fieldName)
    );
    return field;
  }
};

export const getLastNonConditionalIdx = (index, questionSets) => {
  let nonConditionalIndex = index - 1;
  while (
    nonConditionalIndex >= 0 &&
    getFieldValueByFieldName(
      questionSets?.[nonConditionalIndex]?.value,
      QuestionSetTypes.CONDITIONAL_TOGGLE
    )
  ) {
    nonConditionalIndex--;
  }
  return nonConditionalIndex;
};

export const setDynamicQuestionLabels = (questionSets) => {
  let conditionalCount = 0;
  let nonConditionalCount = 0;

  return questionSets.map((question) => {
    if (
      getFieldValueByFieldName(
        question?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      conditionalCount++;
      return {
        ...question,
        dynamicQuestionLabel: `Conditional Question ${ conditionalCount }`,
      };
    }
    nonConditionalCount++;
    conditionalCount = 0;

    const categoryName = getFieldValueByFieldName(
      question?.value,
      QuestionSetTypes.QUESTION_CATEGORY
    )?.value;

    return {
      ...question,
      dynamicQuestionLabel: categoryName ?? `Item ${ nonConditionalCount }`,
    };
  });
};

/* eslint-disable  max-statements */
export const setUsedInConditionalLabels = (questionSets) => {
  const updatedQuestionsSets = [...questionSets];

  updatedQuestionsSets.forEach((question, index) => {
    if (
      getFieldValueByFieldName(
        question?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      const nonConditionalIndex = getLastNonConditionalIdx(
        index,
        updatedQuestionsSets
      );
      if (nonConditionalIndex >= 0) {
        const prevQuestionSet = updatedQuestionsSets[nonConditionalIndex];
        prevQuestionSet.isUsedInConditional = true;
        question.conditionalPPI = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.EXISTING_PPI
        )?.value;
        question.conditionalLabel = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.QUESTION_LABEL
        );
        question.conditionalQuestionType = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.ANSWER_TYPE
        );
      }
    } else if ('isUsedInConditional' in question) {
      delete question?.isUsedInConditional;
    }
  });

  return updatedQuestionsSets;
};

export const getQuestionSetByFieldName = (questionSets, fieldName) => {
  if (isArray(questionSets)) {
    const filteredQuestionSet = questionSets?.find(
      (questionSet) => questionSet?.fieldSetId === +fieldName?.split('-')[1]
    )?.value;
    return filteredQuestionSet;
  }
};

export const getQuestionSetIndex = (questionSets, fieldName) => {
  if (isArray(questionSets)) {
    return questionSets?.findIndex(
      (questionSet) => questionSet?.fieldSetId === +fieldName.split('-')[1]
    );
  }
};

export const findMatchingQuestionSetsWithIndex = (
  questionSets,
  categoryValue,
  currentQuestionSetIdx
) => {
  if (isArray(questionSets) || questionSets.length > 0) {
    return questionSets
      .map((questionSet, index) => ({ questionSet, index }))
      .filter(
        ({ questionSet }, index) =>
          questionSet.value[0].value.value === categoryValue &&
          index !== currentQuestionSetIdx
      );
  }
  return [];
};

export const setConditionalToggledLabel = (initialValues) => {
  const updatedQuestionSets = initialValues?.map((questionSet) => {
    if (
      getFieldValueByFieldName(
        questionSet?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      return {
        ...questionSet,
        hasConditionalToggled: true,
      };
    }
    return questionSet;
  });
  return updatedQuestionSets;
};

export const getConditionalToggleValue = (questionSet) =>
  questionSet?.hasConditionalToggled ||
  getFieldValueByFieldName(
    questionSet?.value,
    QuestionSetTypes.CONDITIONAL_TOGGLE
  );

export function getCategoriesFromForm(form) {
  const formValues = form?.questionSet
    ? form?.questionSet?.updatedFieldSet
    : form;

  const categoryContent =
    isArray(formValues) &&
    formValues
      .filter((itm) =>
        getFieldValueByFieldName(itm.value, QuestionSetTypes.QUESTION_CATEGORY)
      )
      .map(
        (itm) =>
          getFieldValueByFieldName(
            itm.value,
            QuestionSetTypes.QUESTION_CATEGORY
          )?.value
      );

  return categoryContent
    ? {
      categories: [...new Set(categoryContent)].map((val) => {
        return {
          name: val,
          handle: val,
          questions: categoryContent.filter((question) => question === val),
        };
      }),
    }
    : [];
}

// Returns the indices of questionSets with hasConditionalToggled:true starting from the given index
// and moving forward until a questionSet without hasConditionalToggled is encountered
export const getConditionalSetFromFirst = (startIndex, questionSets) => {
  //Adding the startIndex (which contains the isUsedInConditional:true) initially
  const result = [startIndex];
  for (let i = startIndex + 1; i < questionSets.length; i++) {
    const field = questionSets[i];
    if (getConditionalToggleValue(field)) {
      result.push(i);
    } else {
      break;
    }
  }
  return result;
};

// Returns the indices of questionSets with the hasConditionalToggled:true starting from the given index
// and moving backwards until a questionSet with isUsedInConditional:true is encountered
export const getConditionalSetFromLast = (lastIndex, questionSets) => {
  //Adding the lastIndex (which contains the hasConditionalToggled:true) initially
  //since it is the lastIndex of conditionalSet
  const result = [lastIndex];
  for (let i = lastIndex - 1; i >= 0; i--) {
    const field = questionSets[i];
    if (getConditionalToggleValue(field)) {
      result.unshift(i);
    } else if (field?.isUsedInConditional) {
      result.unshift(i);
      break;
    } else {
      break;
    }
  }
  return result;
};

// Returns the indices of the entire conditionalSet for the given index.
// Utilizes getConditionalSetFromFirst and getConditionalSetFromLast above helper functions
export const getFullConditionalSet = (index, questionSets) => {
  // Use getConditionalSetFromLast to iterate backward from the given index
  const backwardSet = getConditionalSetFromLast(index, questionSets);
  const lastIdx = getLastElement(backwardSet);

  // Use getConditionalSetFromFirst to iterate forward from the last index of the backwardSet
  const forwardSet = getConditionalSetFromFirst(lastIdx, questionSets);

  // Merge both sets, ensuring no duplicates
  return Array.from(new Set([...backwardSet, ...forwardSet]));
};

// Returns the indices of questionSets with hideQuestion:true starting from the given index
// and moving forward until a questionSet without hideQuestion property is encountered
// NOTE: We set hideQuestion:true for all ACTIVITY_GEAR_PPIS except ACTIVITY_GEAR_MODEL
export const getHiddenSetFromStart = (startIndex, questionSets) => {
  //Adding the startIndex (which contains the ppi-activity-gear-model) initially
  const result = [startIndex];
  for (let i = startIndex + 1; i < questionSets.length; i++) {
    const field = questionSets[i];
    if (field?.hideQuestion) {
      result.push(i);
    } else {
      break;
    }
  }
  return result;
};

// Returns the indices of questionSets with hideQuestion:true starting from the given index
// and moving backwards until it encounters a questionSet with a PPI_ACTIVITY_GEAR_MODEL ppiValue
export const getHiddenSetFromLast = (lastIndex, questionSets) => {
  const result = [lastIndex];
  for (let i = lastIndex - 1; i >= 0; i--) {
    const field = questionSets[i];
    const ppiValue = getFieldValueByFieldName(
      field.value,
      QuestionSetTypes.EXISTING_PPI
    )?.value;
    if (field?.hideQuestion) {
      result.unshift(i);
    } else if (ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL) {
      result.unshift(i);
      break;
    }
  }
  return result;
};

export const getPPIValue = (field) =>
  getFieldValueByFieldName(field?.value, QuestionSetTypes.EXISTING_PPI)?.value;

export const isActivityGearModelQuestion = (ppiValue) =>
  ppiValue === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL;

// Checks if the given index is the last isActivityGearModelQuestion question in the repeatableFieldSet.
export const checkIfActivityGearModelIsLastQuestion = (
  ppiValue,
  currentHiddenSet,
  repeatableFieldSet
) => {
  if (isActivityGearModelQuestion(ppiValue) && currentHiddenSet?.length > 0) {
    const nextIndexAfterHiddenSet = getLastElement(currentHiddenSet) + 1;
    return !repeatableFieldSet[nextIndexAfterHiddenSet];
  }
  return false;
};

// Checks if the given index is the last conditional question in the repeatableFieldSet.
export const checkIfConditionalIsLastQuestion = (index, repeatableFieldSet) => {
  const conditionalSet = getConditionalSetFromFirst(index, repeatableFieldSet);
  const nextIndexAfterConditionalSet = getLastElement(conditionalSet) + 1;
  return !repeatableFieldSet[nextIndexAfterConditionalSet];
};

// Checks if the given index is the first or last in the conditionalSet.
// Returns first if it's the first index, last if it's the last index, or null otherwise.
export const checkConditionalPosition = (index, repeatableFieldSet) => {
  const conditionalSet = getFullConditionalSet(index, repeatableFieldSet);
  //Taking second index of conditionalSet as the first index has isUsedInConditional:true
  const isFirst = index === conditionalSet[1];
  const isLast = index === getLastElement(conditionalSet);

  //it means only one conditional question exists in the set
  if (isFirst && isLast) return ['first', 'last'];
  if (isFirst) return 'first';
  if (isLast) return 'last';

  return null;
};

// Helper function to check if the question is the first in the conditional set
export const isFirstInConditionalSet = (position) =>
  position === 'first' || (isArray(position) && position.includes('first'));

// Helper function to check if the question is the last in the conditional set
export const isLastInConditionalSet = (position) =>
  position === 'last' || (isArray(position) && position.includes('last'));

// Helper function to check if the current question is the last question
export const checkIfLastQuestion = (
  index,
  conditionalQuestionPosition,
  repeatableFieldSet,
  isUsedInConditional,
  ppiValue,
  currentHiddenSet
  // eslint-disable-next-line max-params
) => {
  return (
    checkIfActivityGearModelIsLastQuestion(
      ppiValue,
      currentHiddenSet,
      repeatableFieldSet
    ) ||
    isLastInConditionalSet(conditionalQuestionPosition) ||
    (isUsedInConditional
      ? checkIfConditionalIsLastQuestion(index, repeatableFieldSet)
      : false)
  );
};

export const handleRemoveFieldSet = (
  setRepeatableFieldSet,
  setId,
  layoutType,
  setFieldTouched
) => {
  setRepeatableFieldSet((prevState) => {
    let updatedFieldSet = [...prevState];
    // Find the index of the field to be removed
    const fieldIndexToRemove = updatedFieldSet.findIndex(
      (field) => field.fieldSetId === setId
    );
    if (fieldIndexToRemove === -1) {
      return updatedFieldSet;
    }
    const ppiValue = getPPIValue(updatedFieldSet[fieldIndexToRemove]);

    if (isActivityGearModelQuestion(ppiValue)) {
      // Remove the selected field and its associated hidden ppi fields
      const hiddenSetIndices = getHiddenSetFromStart(
        fieldIndexToRemove,
        updatedFieldSet
      );
      hiddenSetIndices.reverse().forEach((i) => updatedFieldSet.splice(i, 1));
    } else {
      // Remove just the selected field
      updatedFieldSet = prevState.filter((field) => field.fieldSetId !== setId);
    }

    Object.keys(updatedFieldSet).forEach(
      (item, index) => (updatedFieldSet[item].sortOrder = index + 1)
    );
    return updatedFieldSet;
  });
  if (layoutType === SURVEY_FORM) setFieldTouched('questionSet', true);
};

export const shouldShowDeleteLink = ({
  hoveredQuestionSet,
  fieldSetId,
  disabled,
  formState,
  questionIndex,
}) => {
  const isHovered = hoveredQuestionSet === fieldSetId;
  const fieldSet =
    hoveredQuestionSet === fieldSetId &&
    formState.find((field) => field.fieldSetId === fieldSetId);
  const isUsedInConditional = fieldSet?.isUsedInConditional;
  const hasEnoughQuestions =
    !disabled && isArray(formState) && formState.length > 1;
  const ppiValue = getPPIValue(fieldSet);
  const currentHiddenSet = isActivityGearModelQuestion(ppiValue)
    ? getHiddenSetFromStart(questionIndex, formState)
    : [];
  const isOnlyHiddenSetExists = currentHiddenSet?.length === formState?.length;
  return (
    isHovered &&
    !(isUsedInConditional || !hasEnoughQuestions || isOnlyHiddenSetExists)
  );
};
