/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { formTypes } from 'lib/propTypes';
import { Button } from '@nike/eds';
import { isArray } from 'lodash';
import { length, propOr } from 'ramda';
import { AccordionStyled } from '@nike/nike-design-system-components';
import { Plus } from '@nike/nike-design-system-icons';
import InquiryQuestion from './InquiryQuestion.js';
import { getFormFields, ppiAnswerOptions } from 'components/forms/Assets/lib';
import { INQUIRIES } from './utils';
import {
  QuestionSetTypes,
  findMatchingQuestionSetsWithIndex,
  getCategoriesFromForm,
  getFieldByFieldName,
  getFieldValueByFieldName,
  isCategoryField,
  setConditionalToggledLabel,
  setDynamicQuestionLabels,
  setUsedInConditionalLabels,
} from './RepeatableFieldsetUtils';
import { SCREENER_FORM } from 'lib/layoutUtils';
import { ReorderingMenu } from './Components/ReorderingMenu';
import { EntityType } from 'lib/enums';
import { userInitiativePermissions } from 'components/RBAC';

const RepeatableFieldSet = ({
  fields,
  setFieldValue,
  setFieldTouched,
  setFormState,
  formState,
  errors,
  disabled,
  layoutType,
  isApproved,
  newPPI = {},
  requiredFields,
  initialValues,
  setHasUnsavedChanges,
}) => {
  const [repeatableFieldSet, setRepeatableFieldSet] = useState([]);
  const [existingPPIOptions, setExistingPPIOptions] = useState([]);
  const fieldKey = 'questionSet';

  useEffect(() => {
    if (
      isArray(fields) &&
      fields.length > 0 &&
      (!getFieldValueByFieldName(
        repeatableFieldSet[0]?.value,
        QuestionSetTypes.QUESTION_LABEL
      ) ||
        existingPPIOptions?.length === 0)
    ) {
      const initialFieldSet = fields;
      setRepeatableFieldSet(initialFieldSet);
      const existingPPI = getFieldByFieldName(
        initialFieldSet[0]?.value,
        QuestionSetTypes.EXISTING_PPI
      )?.options;
      setExistingPPIOptions(existingPPI);
    }
  }, [fields]);

  useEffect(() => {
    if (INQUIRIES.includes(layoutType) && Array.isArray(fields)) {
      const updatedQuestionSetFields = setConditionalToggledLabel(fields);
      setRepeatableFieldSet(updatedQuestionSetFields);
      setFormState(setConditionalToggledLabel(updatedQuestionSetFields));
    }
  }, [layoutType, fields]);

  const handleRepeatableSetChange = useCallback(
    (response, option) => {
      //Select sends key=option.name
      const key = option?.name || response?.target?.name || response;
      const ppiHandle = response?.target?.ppiHandle;
      const isOptionalQuestion = key.includes(
        QuestionSetTypes.OPTIONAL_QUESTION
      );
      const isSelectionTypeQuestion = key.includes(
        QuestionSetTypes.SELECTION_TYPE
      );
      //construct categoryName
      const categoryNameKey = key ? `categoryName-${ key.split('-')[1] }` : '';

      //Text sends response.target.value
      //Select sends response
      const value = response?.label
        ? response
        : key?.includes(QuestionSetTypes.CONDITIONAL_OPTIONS)
          ? response?.map((opt) => ({
            label: opt?.name || opt?.label,
            value: opt?.value,
          }))
          : key?.includes(QuestionSetTypes.CONDITIONAL_TOGGLE) ||
          isOptionalQuestion
            ? response?.target?.checked
            : (response?.target?.type === 'checkbox'
              ? response?.target?.checked
              : response?.target?.value) || option;

      let updatedFieldSet;

      if (isOptionalQuestion) {
        setRepeatableFieldSet((prevState) => {
          updatedFieldSet = [...prevState];
          const questionSetIndex = updatedFieldSet.findIndex(
            (questionSet) => questionSet.fieldSetId === +key.split('-')[1]
          );
          if (questionSetIndex === -1) return prevState;
          updatedFieldSet[questionSetIndex].optional = value;
          return updatedFieldSet;
        });
      } else if (isSelectionTypeQuestion) {
        const fieldSetId = +key.split('-')[1];
        setRepeatableFieldSet((prevState) => {
          updatedFieldSet = [...prevState];
          const questionSetIndex = updatedFieldSet.findIndex(
            (questionSet) => questionSet.fieldSetId === fieldSetId
          );
          if (questionSetIndex === -1) return prevState;
          const questionOrderIndex = updatedFieldSet[
            questionSetIndex
          ].value.findIndex(
            (val) =>
              val.field ===
              `${ QuestionSetTypes.CONDITIONAL_OPTIONS }-${ fieldSetId }`
          );
          if (questionOrderIndex !== -1) {
            updatedFieldSet[questionSetIndex].value[
              questionOrderIndex
            ].selectionType = value;
          }
          return updatedFieldSet;
        });
      } else {
        setRepeatableFieldSet((prevState) => {
          updatedFieldSet = [...prevState];

          const questionSetIndex = updatedFieldSet.findIndex((obj) =>
            obj.value.some((val) => val.field === key)
          );
          const questionOrderIndex = updatedFieldSet[
            questionSetIndex
          ].value.findIndex((val) => val.field === key);

          if (key.includes(QuestionSetTypes.QUESTION_CATEGORY)) {
            const questionSetIndexCat = updatedFieldSet.findIndex((obj) =>
              obj.value.some((val) => val.field === categoryNameKey)
            );
            const questionOrderIndexCat = updatedFieldSet[
              questionSetIndexCat
            ].value.findIndex((val) => val.field === categoryNameKey);

            updatedFieldSet[questionSetIndexCat].value[
              questionOrderIndexCat
            ].value =
              key.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
              value?.length === 1
                ? value[0]
                : value?.value;
          }

          updatedFieldSet[questionSetIndex].value[questionOrderIndex].value =
            key.includes(QuestionSetTypes.CONDITIONAL_OPTIONS) &&
            value?.length === 1
              ? value[0]
              : value;

          if (isCategoryField(key?.split('-')[0])) {
            const categoryValue = getFieldValueByFieldName(
              updatedFieldSet[questionSetIndex]?.value,
              QuestionSetTypes.QUESTION_CATEGORY
            )?.value;

            const matchingQuestionSetsWithIndex =
              findMatchingQuestionSetsWithIndex(
                updatedFieldSet,
                categoryValue,
                questionSetIndex
              );

            if (matchingQuestionSetsWithIndex) {
              matchingQuestionSetsWithIndex.forEach(
                ({ index: questionSetIdx }) => {
                  updatedFieldSet[questionSetIdx].value[
                    questionOrderIndex
                  ].value = value;
                }
              );
            }
          }

          if (key.includes(QuestionSetTypes.EXISTING_PPI)) {
            const { value: ppiValue } = value || {};
            const answerOptions = getFieldByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.ANSWER_OPTIONS
            );

            if (layoutType === SCREENER_FORM) {
              const { value: ppiValue } = value || {};
              answerOptions.value = ppiAnswerOptions(ppiValue) ?? [];
            } else if (!ppiAnswerOptions(ppiValue)) {
              answerOptions.value = [];
            }

            const conditionalLabel = getFieldValueByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.QUESTION_LABEL
            );

            updatedFieldSet.forEach((questionSet) => {
              if (questionSet?.conditionalLabel === conditionalLabel) {
                // still holds a reference to updatedFieldSet object, not a copy of it.
                const questionSetField = getFieldByFieldName(
                  questionSet.value,
                  QuestionSetTypes.CONDITIONAL_OPTIONS
                );
                questionSetField.value = undefined;
                questionSetField.options = [];
              }
            });
          }

          if (key.includes(QuestionSetTypes.CONDITIONAL_TOGGLE)) {
            // still holds a reference to updatedFieldSet object, not a copy of it.
            const questionCategoryField = getFieldByFieldName(
              updatedFieldSet[questionSetIndex].value,
              QuestionSetTypes.QUESTION_CATEGORY
            );

            if (value) {
              const lastQuestionCategory = [];
              lastQuestionCategory.push(
                getFieldValueByFieldName(
                  updatedFieldSet?.[questionSetIndex - 1]?.value,
                  QuestionSetTypes.QUESTION_CATEGORY
                )?.value
              );

              questionCategoryField.options = lastQuestionCategory.map(
                (category) => {
                  return { label: category, value: category };
                }
              );
            } else {
              const options = updatedFieldSet.map(
                (questionSet) =>
                  getFieldValueByFieldName(
                    questionSet.value,
                    QuestionSetTypes.QUESTION_CATEGORY
                  )?.value
              );
              questionCategoryField.options = [...new Set(options)]
                .filter(Boolean)
                .map((category) => {
                  return { label: category, value: category };
                });
            }
          }

          if (ppiHandle) {
            updatedFieldSet[questionSetIndex].value[
              questionOrderIndex
            ].ppiHandle = ppiHandle;
          }
          return updatedFieldSet;
        });
      }

      if (updatedFieldSet) setFieldValue(fieldKey, updatedFieldSet);
      setFieldTouched(key, true);
      setHasUnsavedChanges(true);
      // setFieldTouched({ [fieldKey]: true });
    },
    [formState]
  );

  const canCreatePPI = userInitiativePermissions('', EntityType.PPI).includes(
    'CREATE'
  );

  const handleAddFieldSet = useCallback(() => {
    const content = getCategoriesFromForm(formState);
    const addedFieldSet =
      (existingPPIOptions?.length > 0 &&
        getFormFields(content, layoutType, existingPPIOptions, canCreatePPI)
          .elements[0].value) ||
      [];

    setRepeatableFieldSet((prevState) => [...prevState, ...addedFieldSet]);
  }, [formState, existingPPIOptions, canCreatePPI]);

  useEffect(() => {
    setFormState(
      setDynamicQuestionLabels(setUsedInConditionalLabels(repeatableFieldSet))
    );
  }, [repeatableFieldSet]);

  const showAddLink =
    !disabled &&
    length(repeatableFieldSet) >= 0 &&
    INQUIRIES.includes(layoutType);

  useEffect(() => {
    if (
      newPPI &&
      isArray(repeatableFieldSet) &&
      repeatableFieldSet.length > 0
    ) {
      const updatedFieldSet = repeatableFieldSet.map((fieldSet) => {
        const ppiField = getFieldByFieldName(
          fieldSet?.value,
          QuestionSetTypes.EXISTING_PPI
        );
        if (ppiField?.value?.value === 'New PPI') {
          const updatedField = fieldSet.value.map((field) => {
            if (field.field === ppiField.field) {
              return {
                ...field,
                options: [...field.options.slice(0, -1), newPPI],
                value: newPPI,
              };
            }
            return field;
          });
          return {
            ...fieldSet,
            value: updatedField,
          };
        }
        return fieldSet;
      });
      setRepeatableFieldSet(updatedFieldSet);
    }
  }, [newPPI]);

  return (
    <>
      { isArray(repeatableFieldSet) && repeatableFieldSet.length > 0 && (
        <>
          <AccordionStyled
            id="eligibilityQuestionForm"
            key="eligibilityQuestionForm"
            open={ true }
            size={ 'large' }
          >
            { isArray(repeatableFieldSet) && repeatableFieldSet.length > 0
              ? repeatableFieldSet.map((fieldSet, idx) => {
                const isCurrentIndexConditional = getFieldValueByFieldName(
                  fieldSet?.value,
                  QuestionSetTypes.CONDITIONAL_TOGGLE
                );
                return (
                  !fieldSet?.hideQuestion && (
                    <div
                      key={ fieldSet.fieldSetId }
                      data-testid={ `inquiryQuestion-${ idx }` }
                    >
                      <div
                        className={
                          isCurrentIndexConditional
                            ? styles.conditionalLayout
                            : styles.layout
                        }
                      >
                        <ReorderingMenu
                          fieldSet={ fieldSet }
                          index={ idx }
                          repeatableFieldSet={ repeatableFieldSet }
                          setRepeatableFieldSet={ setRepeatableFieldSet }
                          disabled={ disabled }
                          setHasUnsavedChanges={ setHasUnsavedChanges }
                          setFieldValue={ setFieldValue }
                          setFieldTouched={ setFieldTouched }
                          layoutType={ layoutType }
                        />
                        <InquiryQuestion
                          questionIndex={ idx }
                          formState={ formState }
                          fields={ fieldSet }
                          disabled={ disabled }
                          setFieldValue={ setFieldValue }
                          setFormState={ setFormState }
                          errors={ propOr([], 'questionSet', errors)[idx] }
                          handleChange={ handleRepeatableSetChange }
                          layoutType={ layoutType }
                          isApproved={ isApproved }
                          requiredFields={ requiredFields }
                          initialValues={ initialValues }
                          setRepeatableFieldSet={ setRepeatableFieldSet }
                          setFieldTouched={ setFieldTouched }
                        />
                      </div>
                    </div>
                  )
                );
              })
              : null }
          </AccordionStyled>

          { showAddLink && (
            <div className={ styles.addItemBtn }>
              <Button
                afterSlot={ <Plus /> }
                size="medium"
                variant="secondary"
                onClick={ handleAddFieldSet }
                data-testid="add-item-btn"
              >
                Add Item
              </Button>
            </div>
          ) }
        </>
      ) }
    </>
  );
};

RepeatableFieldSet.propTypes = {
  ...formTypes,
  disabled: PropTypes.bool,
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  newPPI: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    group: PropTypes.string,
  }),
  setHasUnsavedChanges: PropTypes.func,
};

export default RepeatableFieldSet;
