import React from 'react';
import {
  QuestionSetTypes,
  getLastNonConditionalIdx,
  getQuestionSetIndex,
  getFieldValueByFieldName,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import PropTypes from 'prop-types';
import { Tooltip } from '@nike/eds';
import FieldWrapper from 'components/FieldWrapper';
import { Field } from 'formik';
import { objectOrArray } from 'lib/propTypes';
import { excludeAnswerOptions } from 'components/forms/Assets/InquiryQuestion';
import ToggleComp from './ToggleField';
import { activityGearPPIs } from 'components/forms/Assets/lib';

/* eslint-disable max-statements */
const ConditionalToggle = ({
  formState,
  fieldId,
  fieldValue = false,
  fieldName,
  onChange,
}) => {
  const currentIdx = getQuestionSetIndex(formState, fieldName);
  const lastNonConditionalIndex = getLastNonConditionalIdx(
    currentIdx,
    formState
  );
  const lastNonConditionalQuestionSet =
    formState?.[lastNonConditionalIndex]?.value;
  const lastNonConditionalQuestionLabel = getFieldValueByFieldName(
    lastNonConditionalQuestionSet,
    QuestionSetTypes.QUESTION_LABEL
  );
  const lastNonConditionalAnswerType = getFieldValueByFieldName(
    lastNonConditionalQuestionSet,
    QuestionSetTypes.ANSWER_TYPE
  );
  const lastNonConditionalPPIHandle = getFieldValueByFieldName(
    lastNonConditionalQuestionSet,
    QuestionSetTypes.EXISTING_PPI
  );
  const isTextOrHiddenAnswerType = isTextOrHiddenType(
    lastNonConditionalAnswerType
  );
  const isRestrictedAnswerOptions = excludeAnswerOptions.includes(
    lastNonConditionalPPIHandle?.value
  );
  const hasChecked = formState?.[currentIdx]?.hasConditionalToggled || false;
  const hasUsedInConditional =
    formState?.[currentIdx]?.isUsedInConditional || false;

  const currentPPIValue = getFieldValueByFieldName(
    formState?.[currentIdx]?.value,
    QuestionSetTypes.EXISTING_PPI
  )?.value;

  const disabled =
    formState?.[currentIdx]?.isUsedInConditional ||
    isTextOrHiddenAnswerType ||
    isRestrictedAnswerOptions ||
    !lastNonConditionalQuestionLabel ||
    hasChecked;
  return (
    currentIdx !== 0 &&
    !activityGearPPIs.includes(currentPPIValue) && (
      <FieldWrapper disabled={ hasChecked }>
        <Field key={ fieldId } name={ fieldName }>
          { () => {
            return hasChecked ? (
              <Tooltip
                bodySlot={ `⚠️ Once toggled as a conditional
                    question, it cannot be reverted to a
                    non-conditional question. ` }
                placement={ 'bottom-start' }
                enableFocus={ true }
                isDark={ true }
              >
                <ToggleComp
                  hasChecked={ hasChecked }
                  fieldName={ fieldName }
                  fieldValue={ fieldValue }
                  lastNonConditionalQuestionLabel={
                    lastNonConditionalQuestionLabel
                  }
                  onChange={ onChange }
                  disabled={ disabled }
                />
              </Tooltip>
            ) : hasUsedInConditional ? (
              <Tooltip
                bodySlot={
                  '⚠️ If answer options of this question are used for conditional questions, then it can\'t be toggled as conditional.'
                }
                placement={ 'bottom-start' }
                enableFocus={ true }
                isDark={ true }
              >
                <ToggleComp
                  hasChecked={ hasChecked }
                  fieldName={ fieldName }
                  fieldValue={ fieldValue }
                  lastNonConditionalQuestionLabel={
                    lastNonConditionalQuestionLabel
                  }
                  onChange={ onChange }
                  disabled={ disabled }
                />
              </Tooltip>
            ) : (
              <ToggleComp
                hasChecked={ hasChecked }
                fieldName={ fieldName }
                fieldValue={ fieldValue }
                lastNonConditionalQuestionLabel={
                  lastNonConditionalQuestionLabel
                }
                onChange={ onChange }
                disabled={ disabled }
              />
            );
          } }
        </Field>
      </FieldWrapper>
    )
  );
};

ConditionalToggle.propTypes = {
  fieldId: PropTypes.string,
  fieldName: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fieldValue: PropTypes.bool,
  formState: objectOrArray,
  onChange: PropTypes.func,
  questionSet: objectOrArray,
};

ConditionalToggle.defaultProps = {
  fieldValue: false,
};

export default ConditionalToggle;
