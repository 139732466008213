/* eslint-disable complexity */
/* eslint-disable max-statements */
import { kebabCase, keys as _keys, startCase, isEmpty, isArray } from 'lodash';
import { dissoc, flatten, propOr, hasPath } from 'ramda';
import {
  InitiativeParticipationStatuses,
  ContentStatus,
  PermissionGroups,
  ViewMode,
} from 'lib/enums';
import {
  ACTIVITY_GEAR_PPIS,
  convertHandleToTitle,
  hiddenPPIs,
  ppiAnswerOptions,
} from 'components/forms/Assets/lib';
import { useFetchInitiativeQuery } from 'features/adminApi/endpoints/initiative';
import { useMemo } from 'react';
import { addHours, format, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  Activity,
  Club,
  HeartRate,
  LocationPin,
  Notification,
} from '@nike/nike-design-system-icons';
import {
  QuestionSetTypes,
  getFieldByFieldName,
  getFieldValueByFieldName,
  getFieldValuesByFieldNames,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';

const MOBILE_OVERVIEW_CARD = 'mobile-overview-card';
const MOBILE_RETURNING_CARD = 'mobile-returning-card';
export const MOBILE_DETAIL_SCREEN = 'mobile-detail-screen';
export const WEB_OVERVIEW_CARD = 'web-overview-card';
export const WEB_DETAIL_PAGE = 'web-detail-page';
export const CONSENT_FORM = 'consent-document';
export const SCREENER_FORM = 'screener';
export const SURVEY_FORM = 'survey';
export const DEFAULT_MEDIA = 'default-media';

export const CARD_CONSTANTS = [
  MOBILE_OVERVIEW_CARD,
  MOBILE_RETURNING_CARD,
  MOBILE_DETAIL_SCREEN,
  WEB_OVERVIEW_CARD,
  WEB_DETAIL_PAGE,
];

export const STATE_BASED_CONTENT = [MOBILE_DETAIL_SCREEN, WEB_DETAIL_PAGE];

export const CONTENT_CONSTANTS = [...CARD_CONSTANTS, DEFAULT_MEDIA];

export const CONSENT_CONSTANTS = [CONSENT_FORM];

export const INQUIRY_CONSTANTS = [SCREENER_FORM];

export const SURVEY_CONSTANTS = [SURVEY_FORM];

export const INQUIRY_CONSTANTS_V2 = [SCREENER_FORM, SURVEY_FORM];

export const ASSET_CONSTANTS = [
  ...CONTENT_CONSTANTS,
  ...CONSENT_CONSTANTS,
  ...INQUIRY_CONSTANTS,
  ...SURVEY_CONSTANTS,
];

const TEXT_FIELD = 'text';
const TEXT_AREA = 'textarea';
const IMAGE_FIELD = 'image';
const RICH_TEXT_FIELD = 'richText';
const QUESTION_SET = 'questionSet';

export const permissionsList = [
  {
    label: 'Push Notifications',
    icon: Notification,
  },
  {
    label: 'Location',
    icon: LocationPin,
  },
  {
    label: 'Health Kit',
    icon: HeartRate,
  },
  {
    label: 'Motion and Activity',
    icon: Activity,
  },
  {
    label: 'Social Visibility',
    icon: Club,
  },
];

export const formatCloudinaryImage = (imageFile, maxWidth) => {
  return imageFile.includes('cloudinary') && imageFile.includes('/upload/')
    ? imageFile.replace('/upload/', `/upload/w_${ maxWidth },c_scale/`)
    : imageFile;
};

// eslint-disable-next-line complexity
const layoutValues = (layoutId, initiativeHandle, layoutType, values) => {
  const initiativeValues = {
    initiativeHandle,
    status: ContentStatus.PUBLISHED,
    contentType: layoutType,
    id: layoutId,
  };

  const fields = {
    title: (value) => ({
      value: values?.title || value,
      type: TEXT_FIELD,
      maxChars: 40,
    }),
    subtitle: (value) => ({
      value: values?.subtitle || value,
      type: TEXT_FIELD,
      maxChars: 85,
    }),
    text: (value) => ({
      type:
        kebabCase(layoutType) === WEB_OVERVIEW_CARD
          ? TEXT_AREA
          : RICH_TEXT_FIELD,
      value: values?.text || value,
      maxChars: 300,
    }),
    ctaText: (value) => ({
      type: TEXT_FIELD,
      value: values?.ctaText || value,
      maxChars: 15,
    }),
    logoImage: {
      type: IMAGE_FIELD,
      value: values?.logoImage || null,
      helperText:
        'Please upload a white logo as a transparent PNG or GIF./Recommended size: 240px x 168px./Max file size: 1 MB.',
    },
    backgroundImage: {
      type: IMAGE_FIELD,
      value: values?.backgroundImage || null,
      helperText: 'Recommended size: 984px x 1392px./Max file: size 1 MB.',
    },
    repeatingText: {
      type: RICH_TEXT_FIELD,
      sections: { text: values?.repeatingText, sortOrder: 1 },
      helperText: 'Enter rich text',
    },
    questionSet: {
      type: QUESTION_SET,
      questionSet: { text: values?.questionSet, sortOrder: 1 },
      helperText: 'TBD',
    },
  };

  switch (layoutType) {
    case MOBILE_OVERVIEW_CARD:
      return {
        title: fields.title(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case MOBILE_RETURNING_CARD:
      return {
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case MOBILE_DETAIL_SCREEN:
      return {
        title: fields.title(),
        text: fields.text(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Launch'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case WEB_OVERVIEW_CARD:
      return {
        title: fields.title(),
        subtitle: fields.subtitle('Welcome'),
        text: fields.text(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case WEB_DETAIL_PAGE:
      return {
        title: fields.title(),
        text: fields.text(),
        subtitle: fields.subtitle(),
        ctaText: fields.ctaText('Learn More'),
        logoImage: fields.logoImage,
        backgroundImage: fields.backgroundImage,
        ...initiativeValues,
      };
    case CONSENT_FORM:
      return {
        sections: [fields.repeatingText('')],
        ...initiativeValues,
      };
    case SCREENER_FORM:
      return {
        questionSet: [fields.questionSet('')],
        ...initiativeValues,
      };
    case SURVEY_FORM:
      return {
        questionSet: [fields.questionSet('')],
        ...initiativeValues,
      };
    default:
      return null;
  }
};

function onlyPublishedCardsExist(cardsFiltered) {
  const nonPublishedCardsExists = cardsFiltered.filter(
    (content) => content?.status !== ContentStatus.PUBLISHED
  );

  return !(nonPublishedCardsExists.length > 0);
}

export const retrieveShortHandle = (initiativeHandle, getOrg = false) => {
  const lookupInitiativeDetails = (id) => {
    const { data: initData, isLoading: isInitDataLoading } =
      useFetchInitiativeQuery(id, { skip: !id });
    const initDataReturn = useMemo(() => {
      return !isInitDataLoading ? initData : {};
    }, [isInitDataLoading, initData]);
    return initDataReturn;
  };
  const initiativeDetails = lookupInitiativeDetails(initiativeHandle);
  const shortHandle = propOr('', 'shortHandle', initiativeDetails);
  return !getOrg
    ? shortHandle
    : {
      organization: propOr(
        PermissionGroups.DEFAULT_ORG.toLowerCase(),
        'organization',
        initiativeDetails
      ),
      shortHandle,
    };
};

export function generateDefaultCards(id, layoutData, values, shortHandle) {
  const getLayoutIdByLayoutHandle = (layoutHandle) =>
    layoutData.find(
      ({ status, handle }) =>
        handle === layoutHandle && status === ContentStatus.PUBLISHED
    )?.id;

  const cardsFiltered = layoutData.filter(
    (itm) => itm.contentType !== DEFAULT_MEDIA
  );

  const noCardsPresent = layoutData ? cardsFiltered.length === 0 : false;

  if (noCardsPresent || onlyPublishedCardsExist(cardsFiltered)) {
    const cardsShouldBeUpdated =
      !noCardsPresent && onlyPublishedCardsExist(cardsFiltered);

    const allLayoutTypesWithStates = [
      ...CARD_CONSTANTS.filter(
        (card) => !STATE_BASED_CONTENT.includes(card)
      ).map((card) => {
        return { layoutType: card, handleType: card };
      }),
      ...flatten(
        CARD_CONSTANTS.filter((card) => STATE_BASED_CONTENT.includes(card)).map(
          (card) =>
            InitiativeParticipationStatuses.map(
              (initiativeParticipationStatus) => {
                return {
                  layoutType: card,
                  handleType: `${ card }-${ initiativeParticipationStatus }`,
                  initiativeParticipationStatus,
                };
              }
            )
        )
      ),
    ];

    return allLayoutTypesWithStates.map((layout) => {
      const layoutHandle = constructLayoutHandle(
        shortHandle,
        layout.handleType.toLowerCase()
      );

      const layoutId = noCardsPresent
        ? null
        : cardsShouldBeUpdated && getLayoutIdByLayoutHandle(layoutHandle);

      const call = !layoutId ? 'post' : 'patch';
      const transformedValues = layoutValues(
        layoutId,
        id,
        layout.layoutType,
        values
      );
      if (call === 'patch') {
        transformedValues.id = layoutId;
      }
      transformedValues.initiativeParticipationStatus =
        layout.initiativeParticipationStatus;
      const fieldValues = Object.entries(transformedValues).reduce(
        (acc, [key, field]) => {
          const fieldValue = typeof field === 'object' ? field?.value : field;
          return { ...acc, [key]: fieldValue };
        },
        {}
      );

      return {
        call,
        layoutType: layout.layoutType,
        transformedValues: fieldValues,
      };
    });
  }
}

// eslint-disable-next-line max-params, complexity
export function checkAndUpdateConsentFields(
  id,
  values,
  layoutType,
  layoutIds,
  draftExist,
  shortHandle = ''
) {
  const cardType = kebabCase(layoutType);

  const layoutId =
    values.id ||
    layoutIds.find((layout) => layout.contentType === cardType)?.layoutId;

  const updateType = layoutId && draftExist ? 'patch' : 'post';

  //Update status
  const status =
    cardType === CONSENT_FORM
      ? values?.status === ContentStatus.PUBLISHED && draftExist
        ? ContentStatus.PUBLISHED
        : ContentStatus.PREVIEW
      : ContentStatus.PREVIEW;

  //POST accepted handle and platform
  //PATCH is saying no to handle....
  const transformedValues = {
    status,
    initiativeHandle: id,
    sections: values.sections.map((section) => {
      return { sortOrder: section.sortOrder, text: section.value };
    }),
  };

  if (updateType === 'patch') {
    transformedValues.id = layoutId;
  }

  return {
    call: updateType,
    layoutType: cardType,
    values: transformedValues,
  };
}

const combineDateTime = (date, time) => `${ date }T${ time }`;

export function isEDTInEffect(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  if (isNaN(date.getTime())) {
    return false;
  }

  // Creating a Date object for the second Sunday in March at 2:00 AM
  const startEDT = new Date(year, 2, 8, 2, 0, 0);
  // Calculating the second Sunday in March (month 2, 0-indexed) from March 8th
  startEDT.setDate(8 + ((7 - startEDT.getDay()) % 7));

  // Creating a Date object for the first Sunday in November at 2:00 AM
  const endEDT = new Date(year, 10, 1, 2, 0, 0);
  // Calculating the first Sunday in November (month 10) from November 1st
  endEDT.setDate(1 + ((7 - endEDT.getDay()) % 7));

  // Handling the edge cases around the start and end of EDT
  const oneHourBeforeSecondSundayInMarch = new Date(startEDT);
  oneHourBeforeSecondSundayInMarch.setHours(
    oneHourBeforeSecondSundayInMarch.getHours() + 1
  ); // 1 hour after start of EDT

  const oneHourBeforeFirstSundayInNovember = new Date(endEDT);
  oneHourBeforeFirstSundayInNovember.setHours(
    oneHourBeforeFirstSundayInNovember.getHours() - 1
  ); // 1 hour before end of EDT

  // During the transition from EST to EDT
  if (date >= startEDT && date < oneHourBeforeSecondSundayInMarch) {
    return false; //Since, it's not yet EDT between 1:00 AM and 2:00 AM]
  }
  // During the transition from EDT to EST
  if (date >= oneHourBeforeFirstSundayInNovember && date < endEDT) {
    return false; //Since, it's still EDT between 1:00 AM and 2:00 AM]
  }
  // General check if the given date is within the EDT period
  return date >= startEDT && date < endEDT;
}

export const convertETtoUTC = (dateTime) => {
  if (!dateTime) {
    return null;
  }
  const parsedDateTime = parse(dateTime, 'yyyy-MM-dd\'T\'HH:mm', new Date());
  if (isNaN(parsedDateTime.getTime())) {
    return null;
  }
  const isEDT = isEDTInEffect(dateTime);
  const offset = isEDT ? 4 : 5;
  const adjustedDateTime = addHours(parsedDateTime, offset);
  return format(adjustedDateTime, 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'');
};

export const adjustFromUTCtoET = (dateTime) => {
  if (!dateTime) {
    return null;
  }
  // Appending 'T00:00:00Z'/ :00Z to the input date if no time is provided
  const dateTimeWithTime =
    dateTime.length === 10
      ? `${ dateTime }T00:00:00Z`
      : dateTime.includes('T') && !dateTime.endsWith('Z')
        ? `${ dateTime }:00Z`
        : dateTime;

  const utcDate = parseISO(dateTimeWithTime);
  if (isNaN(utcDate.getTime())) {
    return null;
  }
  const easternDate = utcToZonedTime(utcDate, 'America/New_York');
  const formattedDate = format(easternDate, 'yyyy-MM-dd\'T\'HH:mm');
  return formattedDate;
};

const generatePPILabel = (str) => {
  if (str.startsWith('ppi-')) {
    return startCase(str.slice(4));
  }
  return str;
};

// eslint-disable-next-line
export function checkAndUpdateScreenerFields(
  id,
  values,
  layoutType,
  layoutIds,
  draftExist,
  data,
  shortHandle,
  viewMode
) {
  let updatedValues = values;
  if (layoutType === SCREENER_FORM || layoutType === SURVEY_FORM) {
    const gearModelQuestion =
      !isEmpty(values) &&
      values.questionSet.filter((question) => {
        const existingPPI =
          getFieldValueByFieldName(
            question.value,
            QuestionSetTypes.EXISTING_PPI
          )?.value || '';

        return existingPPI === ACTIVITY_GEAR_PPIS.PPI_ACTIVITY_GEAR_MODEL;
      });

    const isAllHiddenPPIsExist =
      !isEmpty(values) &&
      hiddenPPIs.every((hiddenPPI) => {
        return values.questionSet.some((question) => {
          const existingPPI =
            getFieldValueByFieldName(
              question.value,
              QuestionSetTypes.EXISTING_PPI
            )?.value || '';

          return existingPPI === hiddenPPI;
        });
      });

    if (gearModelQuestion.length > 0 && !isAllHiddenPPIsExist) {
      updatedValues = JSON.parse(JSON.stringify(values));
      let baseFieldSetId = gearModelQuestion[0].fieldSetId;
      let baseSortOrder =
        values.questionSet.length || gearModelQuestion[0].sortOrder;

      hiddenPPIs.forEach((ppi) => {
        const newQuestion = JSON.parse(JSON.stringify(gearModelQuestion[0]));
        baseFieldSetId += 1;
        baseSortOrder += 1;
        newQuestion.fieldSetId = baseFieldSetId;
        newQuestion.sortOrder = baseSortOrder;
        newQuestion.value.forEach((field) => {
          if (field.field.includes(QuestionSetTypes.EXISTING_PPI)) {
            field.value = { label: generatePPILabel(ppi), value: ppi };
          }
          if (field.field.includes(QuestionSetTypes.ANSWER_TYPE)) {
            field.value = 'hidden';
          }
          if (field.field.includes(QuestionSetTypes.ANSWER_OPTIONS)) {
            field.ppiHandle = ppi;
          }
        });
        updatedValues.questionSet.push(newQuestion);
      });
    } else if (isEmpty(gearModelQuestion)) {
      updatedValues.questionSet = updatedValues.questionSet.filter(
        (question) => {
          const existingPPI =
            getFieldValueByFieldName(
              question.value,
              QuestionSetTypes.EXISTING_PPI
            )?.value || '';
          return !hiddenPPIs.includes(existingPPI);
        }
      );
    }
  }
  const contentType = kebabCase(layoutType);
  const layoutId =
    updatedValues.id ||
    layoutIds?.find((layout) => layout.contentType === contentType)?.layoutId;
  const updateType = layoutId && draftExist ? 'patch' : 'post';
  const { isAutomated } = updatedValues?.attributes;
  const categoryList =
    !isEmpty(updatedValues) &&
    updatedValues.questionSet.map((question) =>
      getFieldValuesByFieldNames(question.value, [
        QuestionSetTypes.QUESTION_CATEGORY,
        QuestionSetTypes.CATEGORY_NAME,
        QuestionSetTypes.CATEGORY_TITLE,
        QuestionSetTypes.CATEGORY_SUBTITLE,
        QuestionSetTypes.CATEGORY_TEXT,
        QuestionSetTypes.BACKGROUND_IMAGE,
        QuestionSetTypes.CATEGORY_CHOICE,
      ])
    );

  const { surveyStartDate, surveyEndDate, surveyTime, scheduleRate } =
    updatedValues;
  const startDateTime = combineDateTime(surveyStartDate, surveyTime);
  const startDateTimeUTC = convertETtoUTC(startDateTime);
  const endDateTime = combineDateTime(surveyEndDate, surveyTime);
  const endDateTimeUTC = endDateTime ? convertETtoUTC(endDateTime) : null;
  const schedule = {
    startDate: startDateTimeUTC,
    endDate: scheduleRate === 'Once' ? startDateTimeUTC : endDateTimeUTC,
    rate:
      scheduleRate === 'Once'
        ? null
        : {
          unit: 'days',
          value: scheduleRate === 'Daily' ? 1 : 7,
        },
  };

  const allCategories = categoryList.reduce((acc, category) => {
    const key = category[QuestionSetTypes.QUESTION_CATEGORY]?.value;
    if (!acc[key]) {
      acc[key] = { ...category };
    }
    return acc;
  }, []);

  const choicesFormat = (choiceSet) => {
    const hasName = hasPath([0, 'name'], choiceSet);
    const result =
      choiceSet &&
      (hasName
        ? choiceSet
        : choiceSet.map((itm, idx) => {
          const baseItem = {
            name: typeof itm === 'object' ? itm.value : itm.label || itm,
            sortOrder: idx,
            value: itm.value || itm,
            selected: false,
          };

          return layoutType === SCREENER_FORM
            ? {
              ...baseItem,
              ...{
                attributes: {
                  isReject: !!(itm.isReject && isAutomated),
                },
              },
            }
            : {
              ...baseItem,
              attributes: {
                description: itm?.description || null, //passing description for surveys now
              },
            };
        }));
    return result;
  };

  const transformedValues = {
    status: 'preview',
    name:
      layoutType === SURVEY_FORM
        ? updatedValues.surveyName
        : `${ id }-${ contentType }`,
    initiativeHandle: id,
    inquiryType: contentType,
    attributes: updatedValues?.attributes ?? null,
    ...(layoutType === SURVEY_FORM && updatedValues?.isSurveyScheduled
      ? { schedule }
      : null),
    categories: Object.values(allCategories).map((category, idx) => {
      return {
        // this will be changed to the populate the categoryName field of a particular categoryHandle
        handle: `cat-${ kebabCase(
          propOr(null, 'value')(category[QuestionSetTypes.QUESTION_CATEGORY])
        ) }`,
        name: category[QuestionSetTypes.CATEGORY_NAME],
        title: category[QuestionSetTypes.CATEGORY_TITLE] || null,
        subtitle: category[QuestionSetTypes.CATEGORY_SUBTITLE] || null,
        text: category[QuestionSetTypes.CATEGORY_TEXT] || null,
        backgroundImage: category[QuestionSetTypes.BACKGROUND_IMAGE] || null,
        sortOrder: category?.sortOrder || idx + 1,
        questions: category[QuestionSetTypes.CATEGORY_CHOICE]
          ? []
          : updatedValues.questionSet
            .filter(
              (question) =>
                propOr(
                  null,
                  'value'
                )(
                  getFieldValueByFieldName(
                    question.value,
                    QuestionSetTypes.QUESTION_CATEGORY
                  )
                ) ===
                  propOr(
                    null,
                    'value'
                  )(category[QuestionSetTypes.QUESTION_CATEGORY])
            )
          //eslint-disable-next-line complexity
            .map((question) => {
              const ppiHandle = getFieldValueByFieldName(
                question.value,
                QuestionSetTypes.EXISTING_PPI
              )?.value;
              const isActivityGearPPI =
                  Object.values(ACTIVITY_GEAR_PPIS).includes(ppiHandle);
              const questionLabel = isActivityGearPPI
                ? generatePPILabel(ppiHandle)
                : getFieldValueByFieldName(
                  question.value,
                  QuestionSetTypes.QUESTION_LABEL
                );
              const answerType = getFieldValueByFieldName(
                question.value,
                QuestionSetTypes.ANSWER_TYPE
              );
              const questionAnswerType = isTextOrHiddenType(answerType)
                ? 'string'
                : answerType || 'choice';
              const isHiddenAnswerType =
                  answerType === 'hidden' && !ppiAnswerOptions(ppiHandle);
              const displayType = isHiddenAnswerType ? 'hidden' : null;
              const isOptionalQuestion = question?.optional || false;
              const answerOptions =
                  getFieldValueByFieldName(
                    question.value,
                    QuestionSetTypes.ANSWER_OPTIONS
                  ) || [];
              const conditionalToggle = getFieldValueByFieldName(
                question.value,
                QuestionSetTypes.CONDITIONAL_TOGGLE
              );
              let conditionalOptions = [];
              let conditionalOptionShow = [];
              let conditionalSelectionType = 'any';
              let conditionalPPI = '';
              if (conditionalToggle) {
                const conditionalField = getFieldByFieldName(
                  question.value,
                  QuestionSetTypes.CONDITIONAL_OPTIONS
                );
                conditionalSelectionType = conditionalField?.selectionType;
                conditionalPPI = conditionalField?.conditionalPPI;
                conditionalOptions = conditionalField?.value;
                conditionalOptionShow = Array.isArray(conditionalOptions)
                  ? conditionalOptions?.map(({ value }) => value)
                  : [conditionalOptions?.value ?? null].filter(Boolean);
              }
              return {
                name: questionLabel,
                text: questionLabel,
                ppiHandle,
                displayType,
                optional: isOptionalQuestion,
                questionType: questionAnswerType,
                attributes: {
                  ...(isHiddenAnswerType && {
                    deepLinkEnabled: true,
                  }),
                  ...(conditionalToggle && {
                    visibility: 'conditional',
                    'conditional-ppi':
                        question.conditionalPPI ?? conditionalPPI,
                    'conditional-operator':
                        conditionalSelectionType === 'all'
                          ? 'equals'
                          : 'includes',
                    'conditional-option-show': conditionalOptionShow,
                  }),
                  ...(layoutType === SCREENER_FORM &&
                      !isEmpty(answerOptions) && {
                    ...(answerOptions.some((choice) => choice.isReject) &&
                          isAutomated && {
                      hasRejectChoices: true,
                    }),
                    ...(answerOptions.every((choice) => !choice.isReject) &&
                          isAutomated && {
                      hasRejectChoices: false,
                    }),
                  }),
                },
                sortOrder: question.sortOrder || 1,
                //Uses dynamic answer options
                choices:
                    layoutType === SCREENER_FORM || SURVEY_FORM
                      ? choicesFormat(answerOptions)
                      : ppiAnswerOptions(ppiHandle)
                        ? choicesFormat(ppiAnswerOptions(ppiHandle))
                        : questionAnswerType === 'string'
                          ? []
                          : choicesFormat(answerOptions),
              };
            }),
      };
    }),
  };

  // const shortHandle = retrieveShortHandle(id);
  if (updateType === 'patch') {
    transformedValues.id = layoutId;
    transformedValues.attributes = {
      ...JSON.parse(JSON.stringify(transformedValues.attributes)),
      ...(updatedValues?.surveyId && { surveyId: updatedValues.surveyId }),
    };
  } else if (contentType === SURVEY_FORM) {
    const prefixedHandle =
      `svy-${ updatedValues.surveyHandle }` || `svy-${ updatedValues.handle }`;
    //Handling survey handles for existing and new surveys, covers multiple survey scenarios as well
    transformedValues.handle =
      (!draftExist && !layoutId) || viewMode === ViewMode.CREATE
        ? prefixedHandle
        : updatedValues.surveyHandle || updatedValues.handle;
    transformedValues.attributes = {
      ...JSON.parse(JSON.stringify(transformedValues.attributes)),
      ...(updatedValues?.surveyId && { surveyId: updatedValues.surveyId }),
    };
  } else {
    //Uses existing way of creating handles for non-survey assets
    transformedValues.handle = `${ shortHandle }-${ contentType }`;
  }

  return {
    call: updateType,
    inquiryType: contentType,
    values: transformedValues,
  };
}

export function addAssetType(data, assetType, contentType) {
  return {
    ...data,
    assetType,
    contentType,
  };
}

export function matchContentToAssetFormat(layoutData) {
  return {
    ...layoutData,
    assetType: 'content',
  };
}

export function formatAssetData(data, type) {
  const updatedData = [SCREENER_FORM, CONSENT_FORM, SURVEY_FORM].includes(type)
    ? dissoc(
      'platform',
      dissoc('assetType', dissoc('contentType', dissoc('handle', data)))
    )
    : dissoc('platform', dissoc('assetType', dissoc('handle', data)));

  return updatedData;
}

//eslint-disable-next-line max-params
export async function updateLayout(
  updateType,
  layoutId,
  useUpdate,
  contentData
) {
  const siblingData = contentData.filter((item) => item.id === layoutId).pop();

  const dataPerUpdateType =
    updateType === 'patch'
      ? dissoc('handle', siblingData)
      : dissoc('id', siblingData);

  const dataCleaned = dissoc(
    'assetType',
    dissoc('createTimestamp', dissoc('updateTimestamp', dataPerUpdateType))
  );

  const transformedValues =
    updateType === 'patch'
      ? { ...dataCleaned, status: 'published' }
      : { ...dataCleaned, status: 'preview' };

  await useUpdate(transformedValues);
}

// eslint-disable-next-line max-params, complexity
export function checkAndUpdateMediaFields(
  id,
  values,
  layoutType,
  layoutIds,
  draftExist,
  initiativeParticipationStatus,
  shortHandle = ''
) {
  const contentType = kebabCase(layoutType);

  const layoutId =
    values.id ||
    layoutIds.find((layout) => layout.contentType === contentType)?.layoutId;

  const updateType =
    contentType === DEFAULT_MEDIA && layoutId
      ? 'patch'
      : draftExist
        ? 'patch'
        : 'post';

  const transformedValues = {
    initiativeHandle: id,
    status:
      contentType === DEFAULT_MEDIA
        ? ContentStatus.PUBLISHED
        : ContentStatus.PREVIEW,
    contentType,
    text: values.text || values.richText,
    title: values.headline || values.title,
    subtitle: values.tagline || values.subtitle,
    initiativeParticipationStatus: initiativeParticipationStatus || null,
    ctaText: values.ctaText,
    logoImage: values.logoImageFileId || values.logoImage,
    backgroundImage: values.backgroundImageFileId || values.backgroundImage,
  };

  if (updateType === 'patch') {
    transformedValues.id = layoutId;
  }

  return {
    call: updateType,
    layoutType: contentType,
    values: transformedValues,
  };
}

export const getAssetType = (layoutType) => {
  const assetType = layoutType.includes('-')
    ? layoutType
    : kebabCase(layoutType);
  if (CARD_CONSTANTS.includes(assetType)) {
    return 'content';
  }
  return assetType;
};

const getQuestionType = (questionSet) => {
  return questionSet.questionType === 'string'
    ? questionSet.displayType && questionSet.displayType === 'hidden'
      ? 'hidden'
      : 'text'
    : questionSet.questionType;
};

const categoryHandleToCategoryName = (str) => {
  if (str.startsWith('cat-')) {
    return startCase(str.slice(4));
  }
  return str;
};

export function formatInquiryDataForAsset(data, assetType) {
  const sortCategories = (categories) =>
    categories.map((category) => {
      const sortedQuestions = [...category.questions].sort(
        (idx1, idx2) => idx1.sortOrder - idx2.sortOrder
      );
      return {
        ...category,
        questions: !isEmpty(sortedQuestions) ? sortedQuestions : [{}],
      };
    });

  const sortedCategories = sortCategories(data.categories);

  const groupedByCategoryHandle = sortedCategories.reduce(
    (groups, category) => {
      //replace this with handle when the backend is updated
      groups[category.handle ?? category.name] = { ...category };
      return groups;
    },
    {}
  );

  const groupedCategories = Object.keys(groupedByCategoryHandle).map(
    (categoryHandle) => ({
      ...groupedByCategoryHandle[categoryHandle],
      handle: categoryHandleToCategoryName(categoryHandle),
    })
  );

  groupedCategories.sort((cat1, cat2) => cat1.sortOrder - cat2.sortOrder);

  const sortedData = {
    ...data,
    categories: groupedCategories,
  };

  const convertedData = {
    ...sortedData,
    // TODO: Work on sending the schedule object in initialData
    // Currently in AssetForm component schedule object is retrieved from currentData object
    //schedule: {
    //  startDate: adjustFromUTCtoET(sortedData?.schedule?.startDate),
    //  endDate: adjustFromUTCtoET(sortedData?.schedule?.endDate),
    //  rate: sortedData?.schedule?.rate,
    //},
    questionSet: sortedData.categories
      ?.map((categorySet) =>
        categorySet.questions.map((questionSet, idx) => {
          const conditionalOptionShow =
            questionSet?.attributes?.['conditional-option-show'];
          const conditionalOperator =
            questionSet?.attributes?.['conditional-operator'];
          const questionCategory = questionSet.questionCategory;
          const conditionalPPI = questionSet?.attributes?.['conditional-ppi'];
          const conditionalPPIAnsOptions = ppiAnswerOptions(conditionalPPI);
          const conditionalValues = conditionalPPIAnsOptions?.filter((option) =>
            conditionalOptionShow?.includes(option.value)
          );

          const mapOptions = (options) =>
            options?.map((option) => ({
              label: option.label || option.name || option,
              value: option.value || option,
            }));

          const conditionalOptionsVal = isArray(conditionalValues)
            ? mapOptions(conditionalValues)
            : isArray(conditionalOptionShow)
              ? mapOptions(conditionalOptionShow)
              : conditionalOptionShow
                ? { label: conditionalOptionShow, value: conditionalOptionShow }
                : null;

          const conditionalOptions = conditionalPPIAnsOptions?.map(
            (option) => ({
              label: option.label || option.name,
              value: option.value,
            })
          );
          const ppiHandle = questionSet.ppiHandle;
          const isHiddenDisplayType = questionSet.displayType === 'hidden';
          const hideQuestion =
            isHiddenDisplayType && hiddenPPIs.includes(ppiHandle);
          const isOptionalQuestion = questionSet?.optional || false;
          const selectionType =
            conditionalOperator === 'equals' ? 'all' : 'any';

          return {
            field: 'questionSet',
            type: 'questionSet',
            fieldSetId: getRandomInt(),
            sortOrder: questionSet.sortOrder || idx + 1,
            hideQuestion,
            optional: isOptionalQuestion,
            value: [
              {
                field: QuestionSetTypes.QUESTION_CATEGORY,
                sortOrder: 1,
                type: 'select',
                value: questionCategory
                  ? { label: questionCategory, value: questionCategory }
                  : { label: categorySet.handle, value: categorySet.handle },
                label: 'Category',
                options: questionCategory
                  ? { label: questionCategory, value: questionCategory }
                  : { label: categorySet.handle, value: categorySet.handle },
              },
              {
                field: QuestionSetTypes.CATEGORY_NAME,
                sortOrder: 2,
                type: 'text',
                label: 'Category Name',
                value: categorySet.name,
              },
              {
                field: QuestionSetTypes.CATEGORY_TITLE,
                sortOrder: 3,
                type: 'text',
                label: 'Category Title',
                value: categorySet.title,
              },
              {
                field: QuestionSetTypes.CATEGORY_SUBTITLE,
                sortOrder: 4,
                label: 'Category Subtitle',
                type: 'text',
                value: categorySet.subtitle,
              },
              {
                field: QuestionSetTypes.CATEGORY_TEXT,
                sortOrder: 5,
                label: 'Category Text',
                type: 'richText',
                value: categorySet.text,
              },
              {
                field: QuestionSetTypes.BACKGROUND_IMAGE,
                sortOrder: 6,
                label: 'Background Image',
                type: 'backgroundImage',
                value: categorySet.backgroundImage,
              },
              {
                field: QuestionSetTypes.CATEGORY_CHOICE,
                sortOrder: 7,
                label:
                  'This category does not have questions associated with it',
                type: 'choice',
                value: !questionSet.text,
                className: 'categoryFields',
                disabled: !questionSet.text,
              },
              {
                field: QuestionSetTypes.CONDITIONAL_TOGGLE,
                label: 'Conditional Toggle',
                sortOrder: 8,
                type: 'toggle',
                value: questionSet?.attributes?.visibility === 'conditional',
              },
              {
                field: QuestionSetTypes.CONDITIONAL_OPTIONS,
                label: 'Conditional Options',
                sortOrder: 9,
                type: 'select',
                value: conditionalOptionsVal,
                options: conditionalOptions,
                selectionType,
                conditionalPPI,
              },
              {
                field: QuestionSetTypes.QUESTION_LABEL,
                sortOrder: 10,
                type: 'text',
                value: questionSet.text,
                label: 'Question',
              },
              {
                field: QuestionSetTypes.EXISTING_PPI,
                label: 'Associated Profile Property (PPI)',
                sortOrder: 11,
                type: 'select',
                value: {
                  label: convertHandleToTitle(ppiHandle),
                  value: ppiHandle || '',
                },
              },
              {
                field: QuestionSetTypes.ANSWER_TYPE,
                label: 'Answer Selection',
                sortOrder: 12,
                type: 'radioSet',
                value: getQuestionType(questionSet),
              },
              {
                field: QuestionSetTypes.ANSWER_OPTIONS,
                label: 'Options',
                sortOrder: 13,
                type: 'tagsInput',
                value:
                  assetType === SCREENER_FORM || assetType === SURVEY_FORM
                    ? questionSet.choices?.map((choice) => choice)
                    : questionSet.choices?.map((choice) => choice.value),
              },
            ],
          };
        })
      )
      .reduce((acc, curVal) => acc.concat(curVal), []),
  };

  return convertedData;
}

export function getRandomInt() {
  return Math.floor(Math.random() * 9999999);
}

export function constructLayoutHandle(shortHandle, layoutType) {
  return `${ shortHandle }-${ layoutType }`;
}
