import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { Icon } from '@nike/eds';

export const ActionButton = ({ onClick, disabled, icon, label }) => {
  const handleClick = useCallback(
    (event) => {
      if (onClick && !disabled) {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return (
    <Icon
      title={ label }
      onClick={ handleClick }
      name={ icon }
      size="m"
      className={ disabled ? styles.actionBtnDisabled : styles.actionBtnEnabled }
    />
  );
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};
